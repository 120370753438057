// ##### 25-04-07 Komarov
import React, { useState } from "react";
// ##### 25-04-07 Komarov
import { useSelector } from "react-redux";
// ##### 23-03-29 Komarov
import { Link } from "react-router-dom";
import { localizePath } from "../../Pages/Helpers/localizeRoutePath";
// ##### 23-08-29 Komarov
import { IconHint } from "../Icons/iconHint";
// ##### 25-04-07 Komarov
/* import { getPreloadedCityPage, getPreloadedPage } from "../../../Redux/actions";
import { ValidateQuery } from "../../Pages/Helpers/helper"; */

// ##### 23-08-29 Komarov
export const LocalizationNavLink = ({
  to,
  onClick,
  children,
  hintIsPresent = false,
  hintText = "",
}) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-08-07 Komarov
  const handleOnClick = () => {
    if (typeof onClick === "function") onClick();
  };

  // ##### 23-08-29 Komarov
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);
  const [x, setX] = useState(undefined);

  // ##### 25-04-07 Komarov
  /* const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  ); */
  // ##### 25-04-07 Komarov
  /* const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  ); */

  const onMouseEnter = (e) => {
    setIsHintShown(true);
    setY(e.clientY - 50);
    setX(e.clientX);
    // ##### 25-04-07 Komarov
    // setHovered(true);
    e.stopPropagation();
  };

  const onMouseLeave = () => {
    setIsHintShown(false);
    setY(undefined);
    setX(undefined);
    // ##### 25-04-07 Komarov
    // setHovered(false);
  };

  // ##### 25-04-07 Komarov
  // const [hovered, setHovered] = useState(false);
  // const dispatch = useDispatch();
  // const preloadedpages_loaded = useSelector((state) => state.pages.preloadedpages_loaded);
  // ##### 25-04-03 Komarov
  // const preloadedcitiespages_loaded = useSelector((state) => state.pages.preloadedcitiespages_loaded);

  // const deviceType = useSelector((state) => state.deviceType);
  // ##### 25-04-01 Komarov
  // const { user: currentUser } = useSelector((state) => state.auth);
  /* useEffect(() => {
    const search_data = ValidateQuery(to, shouldCypherUrlParams, cypherKey);
    if (to && !to.includes("search_results_hotels") && hovered && !preloadedpages_loaded[search_data.id]) {
      // ##### 25-04-01 Komarov
      if (to.includes("city_overview") && !preloadedcitiespages_loaded[search_data.id]) {
        dispatch(getPreloadedCityPage(search_data.id, deviceType, locale, currentUser.user_id));
      } else {
        dispatch(getPreloadedPage(search_data.id, deviceType, locale));
      }
    }
  }, [hovered]); */

  return (
    <>
      <Link
        to={localizePath(to, locale)}
        // ##### 24-08-07 Komarov
        onClick={handleOnClick}
        // ##### 25-03-31 Komarov
        onMouseEnter={onMouseEnter}
        onMouseLeave={(hintIsPresent && onMouseLeave) || undefined}
      >
        {children}
      </Link>
      {/* ##### 23-08-29 Komarov */}
      {hintText !== "" && (
        <span
          style={
            (x && { position: "absolute", left: x }) || { position: "absolute" }
          }
        >
          <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
        </span>
      )}
    </>
  );
};
